import { useQuery } from '@apollo/react-hooks';

import isJobComplete from '../utils/isJobComplete';

import { GET_ACCOUNTS } from '../graph/accounts';

const useCompleteCheck = ({ jobs, formData }) => {
  const {
    data: { accounts },
  } = useQuery(GET_ACCOUNTS, { fetchPolicy: 'cache-only' });

  const jobsWithParsedMeta = jobs?.map(({ calculatorMeta, ...job }) => {
    const parsedMeta = JSON.parse(calculatorMeta);

    if (!parsedMeta?.subIndustryName || !parsedMeta?.paymentTypeName) {
      return {
        isComplete: true,
      };
    }

    const isComplete = !formData.g_productivity || isJobComplete(parsedMeta);

    return {
      ...job,
      ...parsedMeta,
      isComplete,
    };
  });

  const hasIncomeRules = window.localStorage.getItem('INCOME') === 'true';
  const hasExpenseRules = window.localStorage.getItem('EXPENSE') === 'true';

  const isJobsComplete =
    (jobsWithParsedMeta?.length &&
      jobsWithParsedMeta?.every((job) => job.isComplete)) ||
    (!formData.g_productivity &&
      !formData.g_incomeAndExpense &&
      !formData.g_taxes);

  const isIncomeComplete =
    (!formData?.g_productivity &&
      !formData?.g_incomeAndExpense &&
      !formData?.g_taxes &&
      accounts?.length) ||
    (!formData?.g_productivity && accounts?.length && hasIncomeRules) ||
    (!!formData?.g_productivity &&
      !!formData?.travelTime &&
      (!!formData?.lunchBreakLength || !!formData?.noLunchBreak));

  const isExpensesComplete =
    (formData.hasSavings === 'yes' && !!formData.savingsAmount) ||
    (formData.hasSavings === 'no' && !formData.savingsAmount) ||
    (!formData.g_productivity &&
      !formData.g_incomeAndExpense &&
      !formData.g_taxes) ||
    (formData.g_taxes &&
      !formData.g_incomeAndExpense &&
      !formData.g_productivity &&
      hasExpenseRules);

  return {
    isJobsComplete,
    isIncomeComplete,
    isExpensesComplete,
    jobsWithParsedMeta,
    showResults: isJobsComplete && isIncomeComplete && isExpensesComplete,
  };
};

export default useCompleteCheck;
