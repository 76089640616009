import React, { createContext, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import CookieBar from '../components/CookieBar';

const CookieContext = createContext({
  analyticsCookies: false,
  showCookieBar: true,
  showCookiePreferences: false,
});

const CookieProvider = ({ children }) => {
  const [analyticsCookies, setAnalyticsCookies] = useLocalStorageState(
    'analyticsCookies',
    false,
  );
  const [showCookieBar, setShowCookieBar] = useLocalStorageState(
    'showCookieBar',
    true,
  );
  const [showCookiePreferences, setsShowCookiePreferences] =
    useLocalStorageState('showCookiePreferences', false);

  const handleAllowAllCookies = () => {
    setAnalyticsCookies(true);
    setShowCookieBar(false);
  };

  const handleDisableAllCookies = () => {
    setAnalyticsCookies(false);
    setShowCookieBar(false);
  };

  const handleToggleAnalyticsCookies = () => {
    setAnalyticsCookies(!analyticsCookies);
  };

  const handleCloseCookieBar = () => {
    setShowCookieBar(false);
  };

  const handleCloseCookiePreferences = () => {
    setsShowCookiePreferences(false);
  };

  const handleShowCookiePreferences = () => {
    setsShowCookiePreferences(true);
  };

  useEffect(() => {
    if (analyticsCookies) {
      window[`ga-enable`] = true;
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
    }
  }, [analyticsCookies]);

  return (
    <CookieContext.Provider
      value={{
        analyticsCookies,
        showCookieBar,
        showCookiePreferences,
        handleToggleAnalyticsCookies,
        handleCloseCookieBar,
        handleCloseCookiePreferences,
        handleShowCookiePreferences,
      }}
    >
      {children}

      {!!(
        showCookieBar && !window.location.href.includes('/cookie-policy')
      ) && (
        <CookieBar
          onAllowAllCookies={handleAllowAllCookies}
          onDisableAllCookies={handleDisableAllCookies}
          onCloseCookieBar={handleCloseCookieBar}
          onShowCookiePreferances={handleShowCookiePreferences}
        />
      )}
    </CookieContext.Provider>
  );
};

CookieProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CookieContext, CookieProvider };
